import { useContext, useEffect } from 'react'
import { LoadingRing } from '~/components/molecules'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { ParamsContext } from '~/contexts/ParamsContext'
import { useScreen } from '~/hooks/useScreen'
import { HeaderCheckout, MainPublic, PreRegistrationForm } from '../components'

export default function Home() {
  const { route } = useContext(ParamsContext)
  const { step, isRedirecting, setStep, isHeaderCheckoutShown } = useContext(CheckoutContext)

  const stepsWithSuccessStatus = [
    'checkoutSuccess',
    'financingSuccess',
    'incompleteBasicInfoAnalysis',
    'waitingGuarantorSignature',
    'creditInAnalysis',
  ].includes(step)

  useEffect(() => {
    if (stepsWithSuccessStatus && route === '/') {
      sessionStorage.clear()
    }
  }, [route, setStep, stepsWithSuccessStatus])

  const defaultScreen = (
    <PreRegistrationForm
      title="Boas-vindas"
      subTitle="Informe seu CPF para recuperar um pedido ou conheça a Principia."
      variantButtonText="Conhecer a Principia"
      isButtonGroup
    />
  )

  const { currentScreen } = useScreen({
    defaultScreen,
  })

  if (isRedirecting) {
    return <LoadingRing />
  }

  return (
    <MainPublic>
      {isHeaderCheckoutShown && <HeaderCheckout />}
      {currentScreen}
    </MainPublic>
  )
}
